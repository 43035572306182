@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  color: #1c2534;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00b4b9;
}

/* table of content scroll bar */
.scrollbar-white-shadow::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-white-shadow::-webkit-scrollbar-thumb {
  background-color: grey;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
}

.scrollbar-white-shadow::-webkit-scrollbar-track {
  background-color: transparent;
}
/* table of content scroll bar */

/* nav bar hidden scroll bar  */
.scrollbar-hidden-nav::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}

.scrollbar-hidden-nav::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0);
  border-radius: px;
}

.scrollbar-hidden-nav::-webkit-scrollbar-track {
  background-color: transparent;
}
/* nav bar hidden scroll bar  */

/* Title */
.blogs__tilte {
  @apply text-[18px] font-medium leading-[1.44] tracking-[-0.36px] text-white;
}
/* heading */
.heading-TextSmall {
  @apply text-[18px] max-sm:text-[16px];
}
.heading-TextMedium {
  @apply text-[20px] max-sm:text-[17px];
}
.heading-TextLarge {
  @apply text-[22px] max-sm:text-[19px];
}
/* title */
.title-TextSmall {
  @apply text-[16px] max-sm:text-[12px];
}
.title-TextMedium {
  @apply text-[18px] max-sm:text-[13px];
}
.title-TextLarge {
  @apply text-[20px] max-sm:text-[14px];
}
/* body */
.body-TextSmall {
  @apply text-[16px] max-sm:text-[14px];
}
.body-TextMedium {
  @apply text-[17px] max-sm:text-[15px];
}
.body-TextLarge {
  @apply text-[18px] max-sm:text-[16px];
}
/* blog body */
.blogBody-TextSmall {
  @apply text-[18px] max-sm:text-[17px];
}
.blogBody-TextMedium {
  @apply text-[19px] max-sm:text-[18px];
}
.blogBody-TextLarge {
  @apply text-[20px] max-sm:text-[19px];
}
/* body smalll */
.bodySmall-TextSmall {
  @apply text-[14px] max-sm:text-[13px];
}
.bodySmall-TextMedium {
  @apply text-[15px] max-sm:text-[14px];
}
.bodySmall-TextLarge {
  @apply text-[17px] max-sm:text-[16px];
}
/* foot note */
.footNote-TextSmall {
  @apply text-[12px] max-sm:text-[12px];
}
.footNote-TextMedium {
  @apply text-[13px] max-sm:text-[12px];
}
.footNote-TextLarge {
  @apply text-[14px] max-sm:text-[12px];
}

/* key frames  */

@keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
